<template>
  <v-container>
    <base-material-card
      icon="mdi-account-network"
      title="Vendedores"
      class="px-5 py-3"
    >
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            @click="$router.push({ name: 'VendedorCreate' })"
            >Nuevo</v-btn
          >
          <v-text-field
            v-model="pagination.search"
            append-icon="mdi-magnify"
            label="Búsqueda"
            single-line
            hide-details
            @keyup="
              pagination.currentPage = 1;
              load();
            "
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="vendedores"
            item-key="id"
            hide-default-footer
            disable-pagination
            class="elevation-1"
          >
            <template v-slot:item.status="{ item }">
              {{ item.usuario.enabled ? "Activo" : "Inactivo" }}
            </template>
            <template v-slot:item.acciones="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    icon
                    x-small
                    @click="
                      $router.push({
                        name: 'VendedorEdit',
                        params: { id: item.id },
                      })
                    "
                  >
                    <v-icon>far fa-edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
        <v-col col="12">
          <v-pagination
            v-model="pagination.currentPage"
            class="my-4"
            :length="pagination.lastPage"
            @input="load"
            :total-visible="10"
            color="primary"
          ></v-pagination>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    headers: [
      { text: "Razón social", value: "razon_social", sortable: false },
      { text: "Categoría", value: "categoria.nombre", sortable: false },
      { text: "Subcategoría", value: "subcategoria.nombre", sortable: false },
      { text: "Límite de crédito", value: "credito_limite", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Distribuidor", value: "distribuidor.name", sortable: false },
      { text: "Rutas", value: "rutas", sortable: false },
      { text: "Acciones", value: "acciones", sortable: false },
    ],
    vendedores: [],
    pagination: {
      perPage: 10,
      lastPage: 1,
      currentPage: 1,
      search: null,
    },
  }),
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$http
        .get("/vendedores/", {
          params: {
            page: this.pagination.currentPage,
            search: this.pagination.search,
          },
        })
        .then((response) => {
          this.vendedores = response.data.data;
          this.pagination.lastPage = response.data.last_page;
          this.pagination.currentPage = response.data.current_page;
        });
    },
  },
};
</script>